/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/runs/runs.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { FileImport, Run } from '../interfaces';

export const BACKEND_SAYS_REFRESH_ONE_RUN =     '[Runs] Backend Message to Refresh Run';
export const CREATE_CHILD_RUN =                 '[Runs] Create Child Run';
export const CREATE_RUN =                       '[Runs] Create';
export const CREATE_RUN_SUCCESS =               '[Runs] Create Success';
export const DELETE_RUN =                       '[Runs] Delete';
export const DELETE_RUN_SUCCESS =               '[Runs] Delete Success';
export const INPUT_DECK_AUTOLINK =              '[Runs] Input Deck Autolink';
export const INPUT_DECK_DELETE =                '[Runs] Input Deck Delete';
export const INPUT_DECK_UPLOAD_SUCCESS =        '[Runs] Input Deck Upload Success';
export const LINK_FILE_IMPORT =                 '[Runs] Link File Import';
export const LINK_FILE_IMPORT_SUCCESS =         '[Runs] Link File Import Success';
export const LOAD_ONE_AND_SELECT =              '[Runs] Load One Run and Select';
export const LOAD_ONE_AND_SELECT_FAIL =         '[Runs] Load One Run and Select Fail';
export const LOAD_ONE_AND_SELECT_SUCCESS =      '[Runs] Load One Run and Select Success';
export const LOAD_ONE_QUIETLY =                 '[Runs] Load One Run Quietly';
export const LOAD_ONE_QUIETLY_FAIL =            '[Runs] Load One Run Quietly Fail';
export const LOAD_ONE_QUIETLY_SUCCESS =         '[Runs] Load One Run Quietly Success';
export const LOAD_RUNS =                        '[Runs] Load Runs';
export const LOAD_RUNS_FAIL =                   '[Runs] Load Runs Fail';
export const LOAD_RUNS_SET_LOADING =            '[Runs] Load Set Loading';
export const LOAD_RUNS_SUCCESS =                '[Runs] Load Runs Success';
export const QUEUE_ON_CLUSTER =                 '[Runs] Queue On Cluster';
export const QUEUE_ON_CLUSTER_SUCCESS =         '[Runs] Queue On Cluster Success';
export const REFRESH_RUNS =                     '[Runs] Refresh';
export const REFRESH_ONE_RUN =                  '[Runs] Refresh One Run';
export const RUN_ON_TEST_MACHINE =              '[Runs] Run On Test Machine';
export const RUN_ON_TEST_MACHINE_SUCCESS =      '[Runs] Run On Test Machine Success';
export const SELECT_RUN =                       '[Runs] Select';
export const SELECT_RUN_CLEAR =                 '[Runs] Select Clear';
export const STOP_RUN_ON_CLUSTER =              '[Runs] Stop Run On Cluster';
export const STOP_RUN_ON_CLUSTER_SUCCESS =      '[Runs] Stop Run On Cluster Success';
export const STOP_RUN_ON_TEST_MACHINE =         '[Runs] Stop Run On Test Machine';
export const STOP_RUN_ON_TEST_MACHINE_SUCCESS = '[Runs] Stop Run On Test Machine Success';
export const STORE_FILTERED_RUNS =              '[Runs] Store Filtered Runs';
export const UNLINK_FILE_IMPORT =               '[Runs] Unlink File Import';
export const UNLINK_FILE_IMPORT_SUCCESS =       '[Runs] Unlink File Import Success';
export const DELETE_FILE_IMPORT =               '[Runs] Delete File Import';
export const DELETE_FILE_IMPORT_SUCCESS =       '[Runs] Delete File Import Success';
export const UPDATE_RUN =                       '[Runs] Update';
export const UPDATE_RUN_SUCCESS =               '[Runs] Update Success';

export class CreateChildRun implements Action {
  readonly type = CREATE_CHILD_RUN;
  constructor(public payload: { parent: Run, run: Run }) { }
}

export class Create implements Action {
  readonly type = CREATE_RUN;
  constructor(public payload: Run) { }
}

export class CreateSuccess implements Action {
  readonly type = CREATE_RUN_SUCCESS;
  constructor(public payload: Run) { }
}

export class Delete implements Action {
  readonly type = DELETE_RUN;
  constructor(public payload: Run) { }
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_RUN_SUCCESS;
  constructor(public payload: Run) { }
}

export class FilterSuccess implements Action {
  readonly type = STORE_FILTERED_RUNS;
  constructor(public payload: Run[]) { }
}

export class InputDeckAutolink implements Action {
  readonly type = INPUT_DECK_AUTOLINK;
  constructor(public payload: Run) {
  }
}

export class InputDeckDelete implements Action {
  readonly type = INPUT_DECK_DELETE;
  constructor(public payload: Run) { }
}

export class InputDeckUploadSuccess implements Action {
  readonly type = INPUT_DECK_UPLOAD_SUCCESS;
  constructor(public payload: any) { }
}

export class LinkFileImport implements Action {
  readonly type = LINK_FILE_IMPORT;
  constructor(public payload: { fileImport: FileImport, fileType: string, uuid: string }) { }
}

export class LinkFileImportSuccess implements Action {
  readonly type = LINK_FILE_IMPORT_SUCCESS;
  constructor(public payload: FileImport) { }
}

export class Load implements Action {
  readonly type = LOAD_RUNS;
}

export class LoadFail implements Action {
  type = LOAD_RUNS_FAIL;
  constructor(public payload: any) { }
}

export class LoadOneAndSelect implements Action {
  readonly type = LOAD_ONE_AND_SELECT;
  constructor(public payload: string) { }
}

export class LoadOneAndSelectFail implements Action {
  type = LOAD_ONE_AND_SELECT_FAIL;
  constructor(public payload: any) { }
}

export class LoadOneAndSelectSuccess implements Action {
  readonly type = LOAD_ONE_AND_SELECT_SUCCESS;
  constructor(public payload: Run) { }
}

export class LoadOneQuietly implements Action {
  readonly type = LOAD_ONE_QUIETLY;
  constructor(public payload: string) { }
}

export class LoadOneQuietlyFail implements Action {
  type = LOAD_ONE_QUIETLY_FAIL;
  constructor(public payload: any) { }
}

export class LoadOneQuietlySuccess implements Action {
  readonly type = LOAD_ONE_QUIETLY_SUCCESS;
  constructor(public payload: Run) { }
}

export class LoadStarted implements Action {
  readonly type = LOAD_RUNS_SET_LOADING;
}

export class LoadSuccess implements Action {
  readonly type = LOAD_RUNS_SUCCESS;
  constructor(public payload: Run[]) { }
}

export class QueueOnCluster implements Action {
  readonly type = QUEUE_ON_CLUSTER;
  constructor(public payload: { run: Run, cores: number, duration: number, output_name: string }) { }
}

export class QueueOnClusterSuccess implements Action {
  readonly type = QUEUE_ON_CLUSTER_SUCCESS;
  constructor(public payload: Run) { }
}

export class RefreshOneRun implements Action {
  readonly type = REFRESH_ONE_RUN;
}

export class RefreshRuns implements Action {
  readonly type = REFRESH_RUNS;
}

export class RunOnTestMachine implements Action {
  readonly type = RUN_ON_TEST_MACHINE;
  constructor(public payload: { run: Run, cores: number, output_name: string }) { }
}

export class RunOnTestMachineSuccess implements Action {
  readonly type = RUN_ON_TEST_MACHINE_SUCCESS;
  constructor(public payload: Run) { }
}

export class Select implements Action {
  readonly type = SELECT_RUN;
  constructor(public payload: Run) { }
}

export class SelectClear implements Action {
  readonly type = SELECT_RUN_CLEAR;
}

export class StopRunOnCluster implements Action {
  readonly type = STOP_RUN_ON_CLUSTER;
  constructor(public payload: Run) {}
}

export class StopRunOnClusterSuccess implements Action {
  readonly type = STOP_RUN_ON_CLUSTER_SUCCESS;
  constructor(public payload: Run) {}
}

export class StopRunOnTestMachine implements Action {
  readonly type = STOP_RUN_ON_TEST_MACHINE;
  constructor(public payload: Run) { }
}

export class StopRunOnTestMachineSuccess implements Action {
  readonly type = STOP_RUN_ON_TEST_MACHINE_SUCCESS;
  constructor(public payload: Run) { }
}

export class UnlinkFileImport implements Action {
  readonly type = UNLINK_FILE_IMPORT;
  constructor(public payload: FileImport) { }
}

export class UnlinkFileImportSuccess implements Action {
  readonly type = UNLINK_FILE_IMPORT_SUCCESS;
  constructor(public payload: FileImport) { }
}

export class DeleteFileImport implements Action {
  readonly type = DELETE_FILE_IMPORT;
  constructor(public payload: FileImport) { }
}

export class DeleteFileImportSuccess implements Action {
  readonly type = DELETE_FILE_IMPORT_SUCCESS;
  constructor(public payload: FileImport) { }
}

export class Update implements Action {
  readonly type = UPDATE_RUN;
  constructor(public payload: Run) { }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_RUN_SUCCESS;
  constructor(public payload: Run) { }
}
